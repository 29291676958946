body {
  margin: 2px; 
  margin-top: 55px;
  background-color:black;
}
a {
  text-decoration: none;
  color: white;
}

h3 {
  color: white;
  font-family: 'Raleway', sans-serif;
}

h4 {
  color: white;
  font-family: 'Raleway', sans-serif;
}

h5 {
  color: white;
  font-family: 'Raleway', sans-serif;
}

p {
  color: white;
  font-family: 'Raleway', sans-serif;
  font-size: 120%
}

li {
  color: white;
  font-family: 'Raleway', sans-serif;
}

li.scoring {
  color: white;
  font-family: 'Raleway', sans-serif;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
img.arenas {
  padding: 4px;
  margin: 4px;
  width: 250px;
}
img.scoring {
  padding: 0px;
  margin: 0px;
  width: 28px;
  height: 28px;
  vertical-align: middle;
}
.scoring {
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 10px;
  padding: 20px;
  background-color: black;
  border-radius: 20px;
}
.results {
  font-size: small;
  color: white;
  font-family: 'Raleway', sans-serif;
  font-style: italic;
  text-align: right;
  margin-right: 30px;
}

a.hover {
  text-decoration: none;
  color: #000;
}

.mw-content-ltr {
  direction: ltr;
}

ul.gallery {
  margin: 0px;
  margin-top: 10px;
  padding: 0px;
  display: block;
}

li.gallerybox {
  vertical-align: top;
  display: inline-block;
  margin: 0px;
}

li.gallerybox-resources {
  vertical-align: top;
  display: inline-block;
  margin: 0px;
}

.pinlist-image {
  float:left;
  width: 143px;
  padding: 0px;
  margin: 0px 0px -15px 0px;
}

.pinbox {
  width: 143px;
}

div.thumb {
  width: 143px;
  padding: 0px;
  margin: 0px auto;
  background-color: black;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

/*
.App {
  text-align: right;
}
*/

.pinname {
  text-align: center;
  font-size: 80%;
  line-height: 120%;
  color: white;
  font-family: 'Raleway', sans-serif;
}
.pinset {
  text-align: center;
  font-size: 60%;
  font-style: italic;
}
.breck {
  text-align: left;
  font-size: 80%;
  line-height: 120%;
  color: #2f3131;
  font-family: 'Raleway', sans-serif;
}

img {
  border: none;
}


.search-box {
  position: fixed;
  top: 7px;
  right: 0px;
  width: 320px;
  z-index: 9998!important;
  display: flex;
  flex-flow: row-wrap;
  align-items: center;
}

.detail-box {
  position: relative;
  background-color: #2f3131;
  padding: 10px;
  margin-top: 65px;
}

@media screen and (max-width: 1020px) {
  .search-box {
    position: static;  
    right: 0px;
    left: 250px;
    top: 7px;
    width: 280px;
    padding: 15px;    
    margin-left: auto;
    margin-right: auto;
    text-align: left;    
  }

  ul.gallery {  
    margin-top: 0px;
    }  

  .detail-box {
      margin-top: 0px;
    }    
}

.page-nav {
  margin: 15px 0 0 0 ;
  border-radius: 0;
  display: inline-block;
  padding-left: 0;
}

.page-nav li a {
    position: relative;
    float: left;
    display: block;
    width: 40px;
    height: 40px;
    font-size: 13px;
    line-height: 40px;
    padding: 0;
    text-align: center;
    background-color: #f5f5f5;
    color: #333333;
}

.set-name {
  margin-top: 10px;
  font-size:medium;
}
.dropdown-item {
  font-size:1em;
  line-height: 90%;
}

.navbar-nav {
  line-height: 100%;
}

@media all and (min-width: 800px) {
	.navbar .nav-item .dropdown-menu{ display: none; }
	.navbar .nav-item:hover .nav-link{  }
	.navbar .nav-item:hover .dropdown-menu{ display: block; }
	.navbar .nav-item .dropdown-menu{ margin-top:0; }
}

@media only screen and (max-device-width : 640px) {
  .pinlist-image { width: 80px; }
  .pinbox { width: 80px; }  
  div.thumb { width: 80px; }
  .dropdown-item {
    font-size:.8em;
    line-height: 60%;
  }
  
}


.footer-text {
  font-size: x-small;
  color: white;
  text-align: center;
}


.column {
  float: left;
  padding: 10px;
}

.left {
  width: 1000px;
}

.right {
  width: 75%;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}


.mw-content-ltr {
  margin-top: 70px;
}

.mw-gallery-traditional {
text-align: center;
}

.mw-gallery-traditional-left {
  text-align: left;
}

.image-gallery-row {
  vertical-align: top;
  display: inline-block;
  margin: 2px;
}
/*
.image-gallery-column {
  width: 300px;
}

*/




.unlock-first-column {
  width: 20%;
  padding: 0 10px 0 0;
  float: left;
}

.unlock-second-column {
  width: 80%;
  padding: 0 10px 0 0;
  float: right;
  padding: 10px;
}

/* Clear floats after the columns */
.unlock-row:after {
  content: "";
  display: table;
  clear: both;
}

.unlock-row {
  background-color: gray;
  border-radius: 20px;
  margin: 0px;
  margin-top: 10px;
}

.unlock-name {
  color: white;
}

.unlock-image {
height: 180px;
display: block;
margin-left: auto;
margin-right: auto;
}

.unlock-header {
  background-color: black;
  padding: 5px;
  border-radius: 10px;
  margin-bottom: 5px;;
}

.unlock-header-name {
  color: white;
}

.unlock-header-power {
  color: white;
}


.five-columns {
  display: flex;
  flex-flow: row wrap;
}
.column-item {
  flex-basis: 19%;
  flex-grow: 1;
  margin: 0.5% !important;
  min-width: 40px;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  color: white;
  font-size: .65em;
  background: #4d4d4d;
  line-height: 140%;
}

.unlock-user {
  background-color: black;
  text-align: left;
  font-weight: bold;
  border-radius: 20px;
  font-size: .75em;
  padding: 12px 10px;
}

.blue-box {
  background-color: #210254;
  border-radius: 20px;
  color: white;
  width: 120px;
  margin: 0.5% !important;
  padding: 5px;
  border-radius: 20px;
  text-align: left;
  font-size: .75em;
  padding: 13px 15px;

}

@media all and (max-width: 800px) {
  .unlock-user { font-size: .65em; }
}

.green-score {
  color: #52ac4f;
}

.unlock-search {
  text-align: center;
}

.unlock-search-input {
  background-color: #4d4d4d;
  color: white;
  font-size: .7em;
  border: 0px;
  border-radius: 30px;
  padding: 7px 20px;
  margin-bottom: 10px;
}
.unlock-search-button {
  color: white;
  font-weight: bold;
  font-size: .7em;
  font-family: 'Raleway', sans-serif;
  background-color: #921c1f;
  border: 0px;
  border-radius: 30px;
  padding: 7px 20px;
  margin-left: 20px;
}

.search-input {
  background-color: #4d4d4d;
  color: white;
  font-family: 'Raleway', sans-serif;
  font-size: .7em;
  border: 0px;
  border-radius: 30px;
  padding: 7px 20px;
  margin-top: 8px;
}
.search-button {
  color: white;
  font-weight: bold;
  font-family: 'Raleway', sans-serif;
  font-size: .7em;
  background-color: #891a10;
  border: 0px;
  border-radius: 30px;
  padding: 7px 20px;
  margin-left: 10px;
  margin-top: 8px;
}


.tt {
  position:relative; /* making the .tooltip span a container for the tooltip text */
  border-bottom:1px dashed #000; /* little indicater to indicate it's hoverable */
}

.tt:before {
  content: attr(data-text); /* here's the magic */
  position:absolute;
  
  /* vertically center */
  top:50%;
  transform:translateY(-50%);
  
  /* move to right */
  left:100%;
  margin-left:15px; /* and add a small left margin */
  
  /* basic styles */
  width:200px;
  padding:10px;
  border-radius:10px;
  background:#000;
  color: #fff;
  text-align:center;

  display:none; /* hide by default */
}

.tt:hover:before {
  display:block;
}

.tt.left:before {
  /* reset defaults */
  left:initial;
  margin:initial;

  /* set new values */
  top:100%;
  right:20%;
  margin-top:25px;
}

.cohort-row {
  display: flex;
  color: white;
}

.cohort-left-column {
  flex: 66%;
  color: white;
  background-color: black;
  text-align: left;
  font-weight: bold;
  border-radius: 20px;
  font-size: .75em;
  padding: 5px 10px;
  margin: 2px;
}

.cohort-right-column {
  flex: 34%;
  color: white;
  background-color: #210254;
  border-radius: 20px;
  color: white;
  margin: 0.5% !important;
  padding: 5px;
  border-radius: 20px;
  text-align: left;
  font-size: .75em;
  padding: 5px 10px;
}

.cohorts {
  width: 320px;
  padding: 0px;
  background-color: gray;
  border-radius: 10px;
  margin: 5px 0px;
  padding: 2px;
}


/* pinList */
.pagination {
  display: inline-block;
}

.pagination a {
  color: white;
  float: left;
  padding: 3px 6px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 3px;
  background-color: #4d4d4d;
  font-family: 'Arial', sans-serif;
}

.pagination a.active {
  background-color: black;
  color: white;
  border: 1px solid white;
}

.pagination a:hover:not(.active) {background-color: black ;}


/* pinDetail Page */
.detail-top-container:after {
  content: "";
  display: table;
  clear: both;
}

.detail-top-child {
  float: left;
  width: 33.33%;
  padding: 0px 20px 0px 0px;
  margin: 0px;
}

.detail-bottom-container:after {
  content: "";
  display: table;
  clear: both;
}
.detail-bottom-child {
  float: left;
  width: 33.33%;
  padding: 0px;
}

hr.detail-divider {
  border: 2px solid black;
  margin: 15px;
  padding: 0px;
}

.tag-list {
  list-style-type: none;
  margin-top: 0px;
  padding: 0;
  overflow: hidden;
}
.tag-list-item {
  float: left;
  margin-right: 15px;
  margin-bottom: 2px;
  border: 0px solid black;
  border-radius: 10px;
  padding: 4px 10px 4px 10px;
  background-color: black;
}

.tag-list-item a {
  color: white;
  font-size: smaller;
  font-weight: bold;
}

.pin-text {
  color: white;
}

.pin-text-category {
  color:darkgray;
}

.image-carousel {
  width: 350px;
}

.sliderimg{
  width: 100%;
  object-fit: cover
}
.sliderimg-white{
  width: 100%;
  object-fit: cover;
  background: whitesmoke;
  border: 2px solid black;
  border-radius: 10px;
}

ul.alice-carousel__dots {
  position: absolute;
  bottom: 10px;
  right: 130px;
  margin: 0px;
  padding: 0px;
}

div.alice-carousel__prev-btn {
  position: absolute;
  bottom: -10px;
  left: -140px;
  font-size: 3em;
  font-family: 'Anton', sans-serif;
  margin: 0px;
  padding: 0px;
}

div.alice-carousel__next-btn {
  position: absolute;
  bottom: -10px;
  right: -140px;
  font-size: 3em;
  font-family: 'Anton', sans-serif;
  margin: 0px;
  padding: 0px;
}

p.alice-carousel__prev-btn-item {
  padding: 0px;
  padding-right: 5px;
}

p.alice-carousel__next-btn-item {
  padding: 0px;
  padding-left: 5px;
}

.production-history {
  background-color: #921c1f;
  color: white;
  width: 320px;
  padding: 3px;
  text-align: center;
  font-size: 80%;
  font-family: 'Raleway', sans-serif;
  border-radius: 10px; 
}

.edition {
  background-color: #4d4d4d;
  border: 1px solid darkgrey;
  width: 320px;
  padding: 10px;
  border-radius: 10px;
  margin: 5px 0px;
}

.declaration {
  background-color: #4d4d4d;
  border: 1px solid darkgrey;
  width: 320px;
  padding: 10px;
  border-radius: 10px;
  margin: 5px 0px;
}

.edition-text-1 {
  font-size: 85%; 
  color: white;
 
}

img.edition-text-1 {
  vertical-align:text-top;
}

.edition-text-2 {
  font-size: 70%; 
  color: white;
}

.buy-text-1 {
  font-size: 100%; 
  color: white;
  vertical-align: bottom;
}

/* Sets */
.box-image {
  width: 200px;
  margin: 10px;
  border-radius: 10%;
  border: 1px solid black;
}
.setbox {
  width: 200px;
  margin: 10px;
}

.videobox {
  width: 500px;
  margin: 10px;
}

/* Where to Buy */
.wherebox {
  width: 143px;
  margin: 5px;
}

.where-image {
  width: 120px;
  margin: 10px;
  border-radius: 20%;
  border: 1px solid black;
}

.home-category {
  font-family: 'Raleway', sans-serif;
  font-size: 120%;
  color: white;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 0px;
  margin: 0px;
}

.videotitle {
  font-family: 'Raleway', sans-serif;
  font-size: 100%;
  color: white;
  background-color: black;
  text-align: center;
  padding-top: 2px;
  padding-bottom: 0px;
  margin: 0px;
}

.name-container {
  width: 350px;
  content: "";
  display: table;
  clear: both;
  padding-bottom: 10px;
}
.name-container:after {
  content: "";
  display: table;
  clear: both;
}

.name-box {
  background-color: #921c1f;
  color: white;
  font-size: 100%;
  border: 2px solid white;
  transform: skew(-10deg);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 0px;
  margin:0px;
  float: left;
  width: 85%;
}
.number-box {
  background-color: black;
  color: white;
  font-size: 100%;
  font-weight: bold;
  text-align: center;
  border: 2px solid white;
  transform: skew(-10deg);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;  
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 0px;
  padding-right: 0px;
  margin: 0px;
  float: left;
  width: 15%;
}


.sb-example-1 .search {
  width: 100%;
  position: relative;
  display: flex;
  position: fixed;
  top: 14px;
  right: 80px;
  width: 200px;
  z-index: 9998!important;
  flex-flow: row-wrap;
  align-items: center;
}
.sb-example-1 .searchTerm {
  width: 100%;
  height: 30px;
  border: 3px solid #921c1f;
  border-right: none;
  padding: 5px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #9DBFAF;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.sb-example-1 .searchTerm:focus{
  color: black;
}
.sb-example-1 .searchButton {
  width: 40px;
  height: 30px;
  border: 3px solid #921c1f;
  border-left: none;
  background: white;
  text-align: left;
  cursor: pointer;
  font-size: 20px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;  
}

.sb-example-1 .searchButton i{
  color: black;
  vertical-align:top;
}

.newrelease-header {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 250px;
  width: 180px;
  padding: 3px;
}

@media screen and (max-width: 1000px) {
  .detail-bottom-child {
    width: 100%;
  }
  .detail-top-child {
    width: 100%;
    padding: 0px;
  }
  .tag-list {
    margin-top: 10px;
    margin-bottom: 5px;
  }
  hr.detail-divider {
    border: 0px solid black;
    margin: 0px;
    padding: 0px;
  }  

  .pagination a {
    padding: 2px 4px;
    text-decoration: none;
    transition: background-color .3s;
    border: 1px solid #ddd;
    border-radius: 0px;
    margin: 0px;
    background-color: #4d4d4d;
    font-family: 'Arial', sans-serif;
    font-size: 65%;
  }  

}

@media screen and (max-width:500px) {
.production-history {
  width: 100%; 
}
.edition {
 width: 100%;
}
.declaration {
  width: 100%;
 }
.image-carousel {
  width: 100%;
}
.pinname {
  font-size: 70%;
}
.where-image { 
  width: 70px; 
  margin: 2px; 
  padding: 0px
}
.box-image { 
  width: 100px; 
  margin: 1px; 
  padding: 0px
}
.setbox {
  width: 100px;
  margin: 5px;
}
.name-box {
  font-size: 95%;
}
.number-box {
  font-size: 95%;
}

.mw-gallery-traditional-left {
  text-align: left;
}

.newrelease-header {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 120px;
  width: 80px;
  padding: 3px;
}
.videobox {
  width: 300px;
  margin: 5px;
}
.scoring {
  margin-left: 3px;
  margin-right: 3px;
  margin-bottom: 3px;
  padding: 10px;
  background-color: black;
  border-radius: 20px;
}

p {
  color: white;
  font-family: 'Raleway', sans-serif;
  font-size: 100%
}


}
